import React from "react"
import PostCardForPostsPage from "../molecules/PostCardForPostsPage"
import { blogPosts } from "../../Content/Blogs"
import useReadingTime from "../../hooks/useReadingTime"
import { blogsPostTypeSlug } from "../../Content/Global"

const recommendedArticles = blogPosts.filter(post => post.recommended)

const RecommendedBlogs = () => {
  const { calculateReadingTime } = useReadingTime()

  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <h2 className="text-[28px] md:text-[38px] md:text-[48px] leading-[1.5em] font-[600] text-[#2A2A2A]">
        Recommended Blogs
      </h2>
      <div className="flex flex-wrap justify-start mt-12">
        {recommendedArticles.map((post, index) => (
          <div key={index} className="w-full md:w-1/2 lg:w-1/3 px-2 mt-5">
            <PostCardForPostsPage
              posttypeSlug={blogsPostTypeSlug}
              componentEC="w-full"
              date={post.date}
              category={post.category}
              title={post.title}
              slug={post.slug}
              description={post.description}
              cardImage={post.cardImage}
              time={calculateReadingTime(post.postContent)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default RecommendedBlogs
